import { HomeContent } from "./components/HomeContent.jsx";
import { AppFooter } from "./components/common/AppFooter.jsx";
import { AppSection } from "./components/common/AppSection.jsx";

import "./App.css";

function App() {
  return (
    <div className="App w-screen h-screen bg-sand overflow-auto relative">
      {/* <AppHeader /> */}

      <AppSection id="home" className="bg-mud flex items-center">
        <HomeContent />
      </AppSection>

      {/*
      <SectionQuote
        quote="Do not feel lonely, the entire universe is inside you. Stop acting so small. You are the universe in ecstatic motion. Set your life on fire."
        author="Rumi"
      />

      <AppSection id="coaching-counselling">
        <CoachingCounsellingContent />
        <AboutContent />
      </AppSection> */}

      {/* <SectionQuote quote="You can't pour from an empty cup, take care of yourself first." /> */}

      {/* <AppSection id="about">
        <AboutContent />
      </AppSection> */}

      {/* <AppSection id="services" className="bg-coconut text-sand">
        <ServicesContent />
      </AppSection>

      <AppSection id="testimonials">
        <TestimonialsContent />
      </AppSection>

      <AppSection id="contact" className="bg-lawn">
        <ContactContent />
      </AppSection> */}

      <AppFooter />
    </div>
  );
}

export default App;
