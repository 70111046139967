import React from "react";
import logo from "../logo3.png";

export const HomeContent = () => {
  return (
    <div>
      <img
        src={logo}
        className="mt-0 sm:mt-0 w-64 m-auto"
        alt="Sprouting coconut"
      />
      <div className="text-center mt-8 ax-w-lg m-auto">
        <h1 className="text-4xl text-sand tracking-wider font-serif">
          Noah Fernandes Counselling
        </h1>
        <h2 className="text-xl text-sand mt-2 font-light sm:font-thin">
          Empowering purpose through self-love and authentic living
        </h2>

        <p className="mt-8 text-sand font-thin">
          I'm currently in the process of updating my website. In the meantime,
          please feel free to follow me on{" "}
          <a
            href="https://www.instagram.com/noahcounselling/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            href="https://www.linkedin.com/in/noah-fernandes-7608092b3/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            LinkedIn
          </a>
          .
        </p>
        <p className="mt-2 text-sand font-thin">
          You can reach out to me by email me at{" "}
          <a
            href="mailto:noah.counselling@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            noah.counselling@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};
